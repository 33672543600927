import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"

//class ErrorBoundary extends React.Component {
//constructor(props) {
//super(props);
//this.state = { hasError: false };
//}

//static getDerivedStateFromError(error) {
//// Update state so the next render will show the fallback UI.
//return { hasError: true };
//}

//componentDidCatch(error, info) {
//// You can also log the error to an error reporting service
//console.log(error);
//console.log(info);
//}

//render() {
//if (this.state.hasError) {
//// You can render any custom fallback UI
//return <h1>Something went wrong.</h1>;
//}

//return this.props.children;
//}
//}

//const rootElement = document.getElementById("root");
//ReactDOM.render(<App />, rootElement);

const IndexPage = () => (
  <Layout>
    <h1>Compatrix Demo</h1>
    <p>Pick a statically-populated compatrix to view:</p>
  </Layout>
)

export default IndexPage
